<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="500px"
    top="15vh"
    :close-on-click-modal="false"
    destroy-on-close
    append-to-body
  >
    <div
      class="orderTest-container"
      style="display: flex; justify-content: space-between; padding: 0 20px"
    >
      <el-upload
        :action="baseURL + '/baseAdmin/common/one-upload-as-save'"
        :show-file-list="false"
        accept=".xlsx"
        :headers="headers"
        :data="data"
        :multiple="false"
        name="files"
        :before-upload="handleBeforeUoload"
        :on-progress="handleProgress"
        :on-success="handleSuccess"
      >
        <el-button class="btn upload-btn" type="primary">模板导入</el-button>
      </el-upload>
      <!-- <el-upload
        class="upload-demo"
        action="http://test.admin.sss.cn/baseAdmin/common/one-upload-as-save"
        accept=".xlsx"
        :headers="headers"
        :data="data"
        :on-Progress="handleProgress"
        :multiple="false"
        name="files"
        :limit="1"
        :on-exceed="handleExceed"
        style="width: 300px"
      >
        <el-button size="small" type="primary">模板导入</el-button>

        <div slot="tip" class="el-upload__tip">
          {{ result }}
        </div>
      </el-upload> -->
      <el-button
        v-if="data.type != 7"
        size="small"
        style="height: 32px; margin-left: 10px"
        @click.native.stop="downLoadTpl"
      >
        下载模板
      </el-button>
    </div>
    <div style="padding: 0 20px; margin-top: 25px">
      <transition name="el-fade-in">
        <el-progress
          v-show="showProg"
          ref="prog"
          :text-inside="true"
          :stroke-width="26"
          :percentage="progressPercent"
        ></el-progress>
      </transition>

      <div
        v-for="(item, index) in filesList"
        :key="index"
        style="
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          cursor: pointer;
        "
        @click="handleGetEnd(item.data)"
      >
        <div>
          <i class="el-icon-document"></i>
          <span>
            {{ item.name }}
          </span>
        </div>
        <div>{{ importRes }}</div>
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { baseURL } from '@/config'
  import { queryData, getEnd } from '@/api/importTpl'
  import { downloadFile } from '@/api/Employee'
  export default {
    name: '',
    components: {},
    data() {
      return {
        importRes: '导入中。。。',
        showDialog: false,
        showProg: false,
        title: '导入',
        progressPercent: 0,
        result: '',
        filesList: [],
        data: { type: 1, dealer_id: '' }, //1商品2客户3盘点数据4期初库存5期初欠款6价格方案产品7导入提成
        headers: { token: '' },
        timer: null,
      }
    },
    computed: {
      baseURL() {
        return baseURL
      },
    },
    watch: {
      showDialog(val) {
        if (!val) {
          this.filesList = []
          if (this.timer) this.clearInterval(this.timer)
          this.$emit('refresh')
        }
      },
    },
    created() {
      this.headers.token = this.$store.getters['user/accessToken']
    },
    mounted() {},
    methods: {
      handleBeforeUoload() {
        this.showProg = true
      },
      handleProgress(event, file, fileList) {
        this.progressPercent = Number(event.percent.toFixed(0))
        console.log(event, file, fileList)
      },
      handleSuccess(res, file, fileList) {
        console.log(res, file, fileList)
        if (res.code !== 200) {
          this.$message.error(res.msg.files)
          return false
        } else {
          console.log(this.$refs.prog)
          this.showProg = false
          this.timer = setTimeout(() => {
            this.progressPercent = 0
          }, 3000)
          this.filesList = [fileList[fileList.length - 1]]
          this.filesList[0].data = res.data
          this.$message.success('上传成功')

          // 上传成功开始检测导入结果
          // this.timer = setInterval(() => {
          this.checkImt(this.filesList[0].data)
          // }, 1300)
        }
      },
      handleExceed(files, fileList) {
        this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件.`
        )
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`)
      },
      close() {
        this.showDialog = false
      },
      downLoadTpl() {
        // queryData({ pageSize: -1, status: 1 }).then((res) => {
        //   console.log('待处理')
        //   console.log(res)
        // })
        // queryData({ pageSize: -1, status: 2 }).then((res) => {
        //   console.log('处理中')
        //   console.log(res)
        // })
        // queryData({ pageSize: -1, status: 3 }).then((res) => {
        //   console.log('已处理')
        //   console.log(res)
        // })
        //1商品2客户3盘点数据4期初库存5期初欠款
        if (this.data.type == 1) {
          // https://down.sd32.cn/import/shangpin.xlsx
          window.open('https://down.sd32.cn/import/shangpin.xlsx')
        } else if (this.data.type == 2) {
          // https://down.sd32.cn/import/kehu.xlsx
          window.open('https://down.sd32.cn/import/kehu.xlsx')
        }else if(this.data.type == 18){
          /*
          *导入预定单模板：https://down.sd32.cn/import/yudingdan.xlsx
          * */
          window.open('https://down.sd32.cn/import/yudingdan.xlsx')
        }

        else if (this.data.type == 4) {
          // 导入期初库存：https://down.sd32.cn/import/qckc.xlsx
          window.open('https://down.sd32.cn/import/qckc.xlsx')
        } else if (this.data.type == 5) {
          // 客户欠款模板
          // https://down.sd32.cn/import/khqk.xlsx
          window.open('https://down.sd32.cn/import/khqk.xlsx')
        } else if (this.data.type == 3) {
          // 盘点
          // window.open('')
          // alert('/depotAdmin/check/down')
          downloadFile('/depotAdmin/check/down', '导入数据.xlsx', {
            check_id: this.data.check_id,
          })
        } else {
        }
      },
      onloading(event, file, fileList) {
        console.log(event, file, fileList)
      },
      // 检测是否导入库成功
      checkImt(id) {
        queryData({ pageSize: -1 }).then((res) => {
          console.log('已处理')
          console.log(res)
          let d = res.data.filter((item) => item.id == id)[0]
          console.log(d)
          if (d.result_text != '') {
            console.log('d.result_text', d.result_text)
            this.importRes = d.result_text + '(点击下载结果)'
            window.clearInterval(this.timer)
          }
        })
      },
      handleGetEnd(id) {
        getEnd({ id }).then((res) => {
          console.log('getE')
          console.log(res)
          let a = document.createElement('a')
          let blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          let objectUrl = window.URL.createObjectURL(blob)
          a.setAttribute('href', objectUrl)
          a.setAttribute('download', '结果.xlsx')
          console.log(a)
          a.click()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
